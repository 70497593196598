<template>
  <div class="ds-wrapper mb-4 md:mb-6" v-if="showPayment">
    <div class="bg-container-60 p-4 md:p-6 rounded-b-2xl md-rounded-b-3xl">
      <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto" @click="showPaymentMethodsCard" v-if="!showPaymentOptions">{{ cardTitle }}</vs-button>
      <div v-show="showPaymentOptions && !showPaymentMethods" class="md:px-2">
        <div class="mb-6">
          <h4>{{ cardTitle }}</h4>
        </div>
        <div class="px-4">
          <p class="mb-2 sm:mb-3">Pay with</p>
          <div class="flex flex-col border-b-list">
            <div class="flex flex-row justify-between align-center py-4" v-for="(paymentMethod, index) in customerPaymentMethods" :key="index">
              <div class="flex flex-row items-center" v-if="paymentMethod.type == 'CARD'">
                <vs-radio v-model="selectedPaymentOption" vs-name="selectedPaymentOption" :id="index" :vs-value="paymentMethod" />
                <label :for="index" class="flex flex-row items-center pointer">
                  <img :src="getCardType(paymentMethod.card_type)" :alt="paymentMethod.card_type" />
                  <p><span class="ml-4 md:ml-6">**** {{ paymentMethod.last_four_digits }}</span><span class="ml-4 md:ml-6">{{ getExpiredYear(paymentMethod) }}</span></p>
                </label>
              </div>
              <div class="flex flex-row items-center" v-if="paymentMethod.type == 'DIRECT_DEBIT'">
                <vs-radio v-model="selectedPaymentOption" vs-name="selectedPaymentOption" :id="index" :vs-value="paymentMethod" />
                <label :for="index" class="flex flex-row items-center pointer">
                  <img :src="directDebitCard" alt="bank" />
                  <p><span class="ml-4 md:ml-6">{{ paymentMethod.accountName }}</span><span class="ml-4 md:ml-6">{{ getAccountNumber(paymentMethod.accountNumber) }}</span></p>
                </label>
              </div>
            </div>
          </div>
          <hr class="mb-6" />
          <div class="mb-12 sm:mb-14">
            <vs-button type="flat" class="mx-auto" @click="showAddPaymentMethod" size="medium">Use another payment method</vs-button>
          </div>
        </div>
        <vs-button color="primary" size="large" class="block w-full max-w-400 mx-auto mb-4" @click="submitPayment" :disabled="!proceedPayment">{{ buttonText }}</vs-button>
        <vs-button type="flat" @click="showPaymentMethodsCard" size="medium" class="block mx-auto mb-2 md:mb-0">Cancel</vs-button>
      </div>
      <PaymentCard
        :cardTitle="cardTitle"
        :showPaymentCard="showPaymentMethods"
        :prWithPartnerDetail="prWithPartnerDetail"
        :legals="legals"
        :selectedAmount="selectedAmount"
        :buttonText="buttonText"
        :showDefault="true"
        :paymentRequestDetails="paymentRequestDetails"
        :numberInput="cardNumberInput"
        :cvvInput="cardCvvInput"
        @cancelPayment="showAddPaymentMethod"
        @addCustomerPayment="prepareSubmitPayment"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import PaymentCard from "./PaymentCard.vue";

// Credit Cards
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");

export default {
  components: { PaymentCard },
  props: {
    title: { type: String },
    showPayment: { type: Boolean },
    prOId: {type: String},
    isChangeMethodOpen: {type: Function},
    reloadPageContent: { type: Function},
    prWithPartnerDetail: {type: Object},
    legals: { type: Object },
    paymentRequestDetails: { type: Object },
  },
  data() {
    return {
      paymentRequest: "",
      selectedOption: "",
      selectedAmount: null,
      disbalePaymentButton: false,
      showPaymentOptions: false,
      showPaymentMethods: false,
      selectedPaymentOption: {},
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      cardNumberInput: "make_payment_number_div",
      cardCvvInput: "make_payment_cvv_div",
      cardTitle: "Repay balance",
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["makePayment"]),
    ...mapGetters("customer", ["getCustomerPaymentMethods"]),

    changeMethod() {
      this.onClose();
      this.isChangeMethodOpen(true);
    },

    goToDetailPage() {
      this.$router.push({
        name: "paymentRequestDetail",
        params: {
          prId: this.$route.params.prId,
        },
      });
    },

    async submitPayment() {
      if (!this.selectedOption || !this.proceedPayment) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one option",
          color: "danger",
        });

        return;
      }
      this.$vs.loading();

      const data = {
        paymentRequestId: this.paymentRequest._id,
        selectedOption: this.selectedOption,
        amount: this.selectedAmount,
        ...this.selectedPaymentOption,
        paymentMode: "existing",
      };

      const payload = {
        data: data,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      await this.makePayment(payload).then((result) => {
        this.$vs.loading.close();
        const message = "Your payment of " + this.formatCurrency(this.selectedAmount || 0) + " was processed successfully.";
        this.showToastMessage("Payment processed", message, "success");
        this.reloadPageContent();
        this.showPaymentOptions = false;
        this.showPaymentMethods = false;
        this.selectedOption = "";
        this.selectedPaymentOption = {};
      }).catch((ex) => {
        this.disbalePaymentButton = false;
        let message = "Sorry, your payment method could not be updated.";

        if (ex.response.data.message) {
          message = ex.response.data.message;
        }

        this.$vs.loading.close();
        this.showToastMessage("Payment failed", message, "error");
      });
    },

    navigateToNewTab(routeParams) {
      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },

    showPaymentMethodsCard() {
      this.showPaymentOptions = !this.showPaymentOptions;
      this.selectedOption = "balance";
      this.selectedAmount = this.paymentRequest.balances ? this.paymentRequest.balances.balance : 0;
      this.selectedPaymentOption = this.customerPaymentMethods.find((item) => item.default == true);

      if (this.selectedPaymentOption.type == "EFT") {
        this.showPaymentMethods = !this.showPaymentMethods;
      }
    },

    showAddPaymentMethod() {
      this.showPaymentMethods = !this.showPaymentMethods;
      if (this.selectedPaymentOption.type == "EFT") {
        this.showPaymentOptions = !this.showPaymentOptions;
      }
    },

    getCardType(cardType) {
      let card = "";

      switch (cardType) {
        case "visa":
          card = this.visaCard;
          break;

        case "mastercard":
          card = this.masterCard;
          break;

        case 'amexCard':
          card = this.amexCard;
          break;

        case "unionpay":
          card = this.unionunionpayCardpay;
          break;

        default:
          card = this.visaCard;
          break;
      }

      return card;
    },

    getExpiredYear(date) {
      if (!date) {
        return null;
      }

      return `${date.month}/${date.year.substring(2, 4)}`;
    },

    getAccountNumber(accNum) {
      return accNum.slice(0, accNum.length - 4).replace(/./g, "*")+ accNum.slice(accNum.length - 4);
    },

    async prepareSubmitPayment(paymentData) {
      this.selectedPaymentOption = paymentData;

       if (!this.proceedPayment) {
        this.$vs.notify({
          title: "Error",
          text: "Please select at least one option",
          color: "danger",
        });
        return;
      }

      const data = {
        selectedOption: this.selectedOption,
        amount: this.selectedAmount,
        type: this.selectedPaymentOption.selectedPaymentMode,
        ...this.selectedPaymentOption,
        paymentMode: "new",
        paymentRequestId: this.paymentRequest._id,
      };

      const payload = {
        data: data,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.$vs.loading();
      await this.makePayment(payload).then((result) => {
        this.$vs.loading.close();
        const message = "Your payment of " + this.formatCurrency(this.selectedAmount || 0) + " was processed successfully.";
        this.showToastMessage("Payment processed", message, "success");
        this.reloadPageContent();
      }).catch((ex) => {
        this.$vs.loading.close();
        let message = "Sorry, your payment method could not be updated.";

        if (ex.response.data.message) {
          message = ex.response.data.message;
        }

        this.showToastMessage("Payment failed", message, "error");
      });
    },
  },

  mounted() {
    this.paymentRequest = this.prWithPartnerDetail;
  },

  watch: {
    prWithPartnerDetail(newVal) {
      if (newVal) {
        this.paymentRequest = this.prWithPartnerDetail;
      }
    },
  },

  computed: {
    customerPaymentMethods() {
      return [ ...this.getCustomerPaymentMethods() ];
    },

    buttonText() {
      return "Pay " + this.formatCurrency(this.selectedAmount || 0);
    },

    proceedPayment() {
      return Object.keys(this.selectedPaymentOption).length > 0 && this.selectedPaymentOption.type !== "EFT";
    }
  }
};
</script>

