<template>
  <div v-show="showPaymentCard">
    <h4>{{ cardTitle }}</h4>

    <vs-row class="pt-8" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
      <vs-col vs-md="6" vs-sm="12" class="max-w-420">
        <div class="flex flex-row justify-center gap-4">
          <div class="w-1/2 md:w-2/5" @click="selectTab('CARD')" v-if="defaultpaymentMethod.type == 'CARD'">
            <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'CARD' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
              <CardPayIcon class="chip-icon" />
              <div class="chip-text">Card</div>
            </vs-chip>
          </div>
          <div class="w-1/2 md:w-2/5" @click="selectTab('DIRECT_DEBIT')" v-if="defaultpaymentMethod.type == 'DIRECT_DEBIT'">
            <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'DIRECT_DEBIT' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
              <DirectDebitIcon class="chip-icon" />
              <div class="chip-text">Direct debit</div>
            </vs-chip>
          </div>
        </div>
        <vs-divider class="my-8" v-if="['DIRECT_DEBIT', 'CARD'].includes(defaultpaymentMethod.type)" />
        <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 'CARD'" >
          <div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2">
            <div v-for="(card, key) in productCardOptions" :key="key" class="text-center" :class="cardType === card || cardType === 'default' ? '' : 'opacity-30'">
              <img :src="getCardType(card)" :alt="card" />
            </div>
          </div>
          <div class="w-full">
            <vs-input name="cardHolder" label="Name on card" v-model="formData.name" :success="cardNameValid" :class="errors.has('cardHolder') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required|cardNameFormat'" data-vv-as="name on card" @change="checkFieldValidation('cardName')" />
            <span class="span-text-validation-danger" v-if="errors.has('cardHolder')"><span class="span-text-validation">{{ errors.first("cardHolder") }}</span></span>
          </div>
          <div class="w-full">
            <div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" >
              <label :for="numberInput" class="input-label">Card number</label>
              <div :id="numberInput" style="height: 28px"></div>
              <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
            </div>
            <span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"><span class="span-text-validation">This card type is not supported. Please try another card</span></span>
            <span class="span-text-validation-danger" v-if="showCardNumberIsRequired"><span class="span-text-validation">Card number is required</span></span>
            <span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"><span class="span-text-validation">Invalid card number</span></span>
          </div>
          <div class="flex flex-row gap-x-6">
            <div class="w-1/2">
              <vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="formData.expiryDate" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardFieldOnBlur" />
              <span class="span-text-validation-danger" v-if="showExpDateIsRequired"><span class="span-text-validation">Card expiry date is required</span></span>
              <span class="span-text-validation-danger" v-else-if="showExpired"><span class="span-text-validation">Expiry date is invalid</span></span>
            </div>
            <div class="w-1/2">
              <div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]">
                <label class="input-label">
                  Security code
                  <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                    <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                    <div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'">
                      <p class="text-primary">The 4 digits on the front of your card.</p>
                      <div class="flex flex-row gap-x-4">
                        <div><img :src="amex1" alt="" /></div>
                      </div>
                    </div>
                    <div class="popper py-2 px-4" v-else-if="productCardOptions.includes(cardType)">
                      <p class="text-primary">The 3 digits on the back of your card.</p>
                      <div class="flex flex-row gap-x-4">
                        <div><img :src="general1" alt="" /></div>
                      </div>
                    </div>
                    <div class="popper py-2 px-4 text-center" v-else>
                      <p>For American Express (left), the 4 digits on the front of your card.</p>
                      <p>For all other cards (right), the 3 digits on the back of your card.</p>
                      <div class="flex flex-row pt-2 gap-x-4">
                        <div><img :src="amex" alt="" /></div>
                        <div><img :src="general" alt="" /></div>
                      </div>
                    </div>
                  </popper>
                </label>
                <div :id="cvvInput" style="height: 28px"></div>
                <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
              </div>
              <span class="span-text-validation-danger" v-if="showCvcIsRequired"><span class="span-text-validation">Security code is required</span></span>
              <span class="span-text-validation-danger" v-else-if="isInvalidCvv"><span class="span-text-validation">Security code is invalid</span></span>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 'DIRECT_DEBIT'">
          <div class="w-full">
            <vs-input name="accountName" label="Account name" v-model="formData.accountName" :success="accountNameValid" :class="errors.has('accountName') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required'" data-vv-as="account name" @change="checkFieldValidation('accountName')" />
            <span class="span-text-validation-danger" v-if="errors.has('accountName')"><span class="span-text-validation">{{ errors.first("accountName") }}</span></span>
          </div>
          <div class="flex flex-row gap-x-6">
            <div class="w-2/5">
              <vs-input
                label="BSB"
                name="bsb"
                :class="!bsbError ? 'hasError' : ''"
                placeholder="000 - 000"
                v-mask="'###-###'"
                v-model="formData.bsb"
                :success="bsbSuccess"
                icon="done"
                icon-after="true"
                icon-size="small"
                v-validate="'required'"
                @blur="handleBsb"
              />
              <span class="span-text-validation-danger" v-if="errors.has('bsb')"><span class="span-text-validation">{{ errors.first("bsb") }}</span></span>
              <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
            </div>
            <div class="w-3/5">
              <vs-input name="accountNumber" label="Account number" v-model="formData.accountNumber" :success="accountNumberValid" :class="errors.has('accountNumber') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required|alphanumFormat'" data-vv-as="account number" @change="checkFieldValidation('accountNumber')" />
              <span class="span-text-validation-danger" v-if="errors.has('accountNumber')"><span class="span-text-validation">{{ errors.first("accountNumber") }}</span></span>
            </div>
          </div>
        </div>

        <div class="flex flex-col gap-y-8" v-if="tabIndex == 'EFT'">
          <div>
            <div class="text-center mb-6 md:mb-5">
              <p class="text-sm">Transfer amount</p>
              <h2>{{ formatCurrency(selectedAmount || 0) }}</h2>
            </div>
            <div class="flex flex-col gap-y-4 md:gap-y-3">
              <div class="flex flex-row items-center gap-x-6">
                <p class="text-sm text-right w-1/2">Account name</p>
                <p class="text-primary text-left w-1/2">{{ prWithPartnerDetail.bankDetails.accountName }}</p>
              </div>
              <div class="flex flex-row items-center gap-x-6">
                <p class="text-sm text-right w-1/2">B2B</p>
                <p class="text-primary text-left w-1/2">{{ maskBsb(prWithPartnerDetail.bankDetails.bsb) }}</p>
              </div>
              <div class="flex flex-row items-center gap-x-6">
                <p class="text-sm text-right w-1/2">Account number</p>
                <p class="text-primary text-left w-1/2">{{ prWithPartnerDetail.bankDetails.accountNumber }}</p>
              </div>
              <div class="flex flex-row items-center gap-x-6">
                <p class="text-sm text-right w-1/2">Reference</p>
                <p class="text-primary text-left w-1/2">
                  {{ prWithPartnerDetail.paymentRequestId }}
                  <vs-icon @click="copyToClipBoard" class="ml-3 pointer icon-gray" icon="content_copy" icon-size="small" />
                </p>
              </div>
              <vs-divider class="my-8 max-w-xs mx-auto" />
            </div>
            <p class="text-body text-center mb-8">You’ll get a confirmation email and/or text message once your bank transfer is registered. This can take up to 3 business days.</p>
            <h3 class="text-body text-center">Thank you!</h3>
          </div>
          <div class="pb-2 md:pb-0">
            <vs-button color="primary" class="w-full" size="large" @click="closePayment">Close</vs-button>
          </div>
        </div>
        <div v-if="['CARD', 'DIRECT_DEBIT'].includes(tabIndex)">
          <div class="flex flex-row items-start mb-14 authorise-text" v-if="showDefault">
            <p>Save as my default payment method</p>
            <vs-switch v-model="formData.defaultPayment" name="defaultPayment" class="ml-4"></vs-switch>
          </div>
          <div class="flex flex-row items-start mb-14">
            <vs-checkbox v-model="acceptTerms" name="acceptTerms" :checked="acceptTerms" id="terms"> </vs-checkbox>
            <div v-if="tabIndex == 'CARD' && payNowLegals.explicitTerms" v-html="payNowLegals.explicitTerms" @click="acceptPaymentTerms" class="authorise-text cursor-pointer"></div>
            <div v-if="tabIndex == 'DIRECT_DEBIT' && directDebitLegals.explicitTerms" v-html="directDebitLegals.explicitTerms" @click="acceptPaymentTerms" class="authorise-text cursor-pointer"></div>
          </div>
          <div class="pb-2 md:pb-0">
            <vs-button :disabled="handleAddPaymentDisable" color="primary" class="w-full mb-4" size="large" @click="addCustomerPaymentMethods">{{ buttonText }}</vs-button>
            <vs-button type="flat" size="medium" class="block mx-auto" @click="closePayment">Cancel</vs-button>
          </div>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { Validator } from "vee-validate";
import CardPayIcon from "../../components/icons/CardPayIcon.vue";
import DirectDebitIcon from "../../components/icons/DirectDebitIcon";
import ToolTipIcon from "../../components/icons/ToolTipIcon";
import BankTransferIcon from "../../components/icons/BankTransferIcon";
import { mask } from 'vue-the-mask';
// Third party import
import Popper from 'vue-popperjs';
import moment from "moment";
import { sentryLog } from "../../../helper/sentryLog";
// Credit Cards
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
// Tooltip images
const amex = require("@/assets/images/cards/amex.png");
const amex1 = require("@/assets/images/cards/amex-1.png");
const general = require("@/assets/images/cards/general.png");
const general1 = require("@/assets/images/cards/general-1.png");

Validator.extend("cardNameFormat", {
  getMessage: () => "Name on card should contain at least one letter",
  validate: (value) => value.match(/[a-zA-Z]/g) !== null,
});

Validator.extend("alphanumFormat", {
  getMessage: () => "Invalid account number",
  validate: (value) => value.match(/^[a-zA-Z0-9]+$/) !== null,
});

export default {
  components: { CardPayIcon, DirectDebitIcon, ToolTipIcon, BankTransferIcon, Popper },
  props: {
    cardTitle: { type: String },
    showPaymentCard: { type: Boolean },
    prWithPartnerDetail: { type: Object },
    legals: { type: Object },
    buttonText: { type: String },
    showDefault: { type: Boolean },
    selectedAmount: { type: Number },
    paymentRequestDetails: { type: Object },
    numberInput: { type: String },
    cvvInput: { type: String },
  },
  data() {
    return {
      appUrl: process.env.VUE_APP_API_URL,
      CardPayIcon: CardPayIcon,
      DirectDebitIcon: DirectDebitIcon,
      ToolTipIcon: ToolTipIcon,
      BankTransferIcon: BankTransferIcon,
      tabIndex: ["CARD"],
      formData: {
        name: "",
        exp_month: "",
        exp_year: "",
        exp_date: "",
        transaction_token: "",
        transaction_data: {},
        paymentRequestId: "",
        merchantId: "",
        expiryDate: "",
        card_type: "",
        accountName: "",
        bsb: "",
        accountNumber: "",
        selectedPaymentMode: "CARD",
        termId: "",
        defaultPayment: false,
      },
      acceptTerms: false,
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      amex: amex,
      amex1: amex1,
      general: general,
      general1: general1,
      tabIndex: 'CARD',
      till_public_key: "",
      paymentJSObject: undefined,
      card_status: {},
      card_options: [],
      isCardAllowed: true,
      showCardNumberIsRequired: false,
      showCvcIsRequired: false,
      dateSuccess: false,
      dateError: true,
      showExpDateIsRequired: false,
      showExpired: false,
      showBsbValid: true,
      bsbSuccess: false,
      bsbError: true,
      cardType: "default",
      cardValid: true,
      cardValidity: true,
      cardFocused: false,
      securityFocused: false,
      cardSuccess: false,
      cvvSuccess: false,
      cardNameValid: false,
      accountNameValid: false,
      accountNumberValid: false,
      isInvalidCvv: false,
      deleteMethodPopup: false,
      selectedIndex: null,
      selectedPaymentMethod: "",
      customerPaymentMethods: [],
      productCardOptions: [],
      defaultpaymentMethod: "",
      payNowLegals: "",
      privacyLegals: "",
      termsAndCondition: "",
      directDebitLegals: "",
      btnDisabled: false,
      updateData: {},
    }
  },
  directives: {
    mask
  },
  methods: {
    getDefaultPaymentMethod() {
      if (this.prWithPartnerDetail.customerPaymentMethods && this.prWithPartnerDetail.customerPaymentMethods.length) {
        this.defaultpaymentMethod = this.prWithPartnerDetail.customerPaymentMethods.find(item => item.default);
        this.tabIndex = this.defaultpaymentMethod.type;
        this.formData.selectedPaymentMode = this.defaultpaymentMethod.type;
      }
    },

    getCardType(cardType) {
      let card = "";

      switch (cardType) {
        case "visa":
          card = this.visaCard;
          break;

        case "mastercard":
          card = this.masterCard;
          break;

        case "amexCard":
          card = this.amexCard;
          break;

        case "unionpay":
          card = this.unionunionpayCardpay;
          break;

        default:
          card = this.visaCard;
          break;
      }

      return card;
    },

    selectTab(index) {
      this.tabIndex = index;
      this.formData.selectedPaymentMode = index;
      this.clearFields();

      if (index == "CARD") {
        this.initializePaymentObject();
      }
    },

    clearFields() {
      this.paymentJSObject = undefined;
      this.cardSuccess = false;
      this.cvvSuccess = false;
      this.cardNameValid = false;
      this.isInvalidCvv = false;
      this.dateSuccess = false;
      this.formData.name = "";
      this.formData.expiryDate = "";
      this.cardType = "default";
      this.bsbSuccess = false;
      this.accountNameValid = false;
      this.accountNumberValid = false;
      this.formData.accountName = "";
      this.formData.bsb = "";
      this.formData.accountNumber = "";
      this.showExpDateIsRequired = false;
      this.showExpired = false;
      this.cardValid = true;
      this.isCardAllowed = true;
      this.showCardNumberIsRequired = false;
      this.acceptTerms = false;
      this.btnDisabled = false;
      this.errors.clear();
    },

    async initializePaymentObject() {
      try {
        this.paymentJSObject = new PaymentJs();
        await this.paymentJSObject.init(this.till_public_key, this.numberInput, this.cvvInput, this.setPaymentStyle);
      } catch(ex) {
        sentryLog(ex);
        return;
      }
    },

    setPaymentStyle(payment) {
      payment.setNumberStyle({
        "width": "100%",
        "border": "none",
        "box-sizing": "border-box",
        "height": "28px",
        "outline": 0,
        "font-family": "Arial",
        "font-size": "14px",
        "color": "#0D0F11",
        "padding": "0 8px",
        "background": "#FBFBFB",
      });
      payment.setCvvStyle({
        "width": "100%",
        "border": "none",
        "outline": 0,
        "font-family": "Arial",
        "box-sizing": "border-box",
        "height": "28px",
        "font-size": "14px",
        "color": "#0D0F11",
        "padding": "0 8px",
        "background": "#FBFBFB",
      });
      payment.numberOn("input", this.checkCardValidity);
      payment.cvvOn("input", this.checkCvvValidity);
      payment.setNumberPlaceholder("0000 0000 0000 0000");
      payment.numberOn("focus", this.handleCardFocus);
      payment.cvvOn("focus", this.handleCvvFocus);
      payment.numberOn("blur", this.handleCardBlur);
      payment.cvvOn("blur", this.handleCvvBlur);
    },

    checkCardValidity(data) {
      this.card_status = data;
      this.cardValid = true;
      this.btnDisabled = false;

      if (this.card_status.numberLength == 0) {
        this.showCardNumberIsRequired = true;
        this.cardType = "";
      }

      if (this.card_status.numberLength > 0) {
        this.showCardNumberIsRequired = false;
      }

      let checkCardAllowedStatus = this.card_options.find((card_option) => {
        return card_option.card.slug === data.cardType;
      });

      if (data.cardType) {
        this.cardType = data.cardType;
        this.cardValidity = data.validNumber;
        this.cardSuccess = data.validNumber && checkCardAllowedStatus;
        this.isCardAllowed = !checkCardAllowedStatus ? false : true;
      } else {
        this.cardSuccess = false;
      }

    },

    handleCardBlur() {
      this.cardValid = this.cardValidity;
      this.cardFocused = false;
      this.btnDisabled = false;
    },

    handleCardFocus() {
      this.cardFocused = true;
    },

    handleCvvBlur() {
      this.securityFocused = false;
    },

    handleCvvFocus() {
      this.securityFocused = true;
    },

    checkCvvValidity(data) {
      this.card_status = data;
      this.cvvSuccess = data.validCvv;
      this.showCvcIsRequired = (this.card_status.cvvLength == 0);

      if (this.card_status.cvvLength > 0) {
        this.showCvcIsRequired = false;
      }

      this.isInvalidCvv = !data.validCvv;
      this.btnDisabled = false;
    },

    handleExpiryCardField(event) {
      const value = event.target.value;
      this.showExpDateIsRequired = (value.length === 0);
      this.checkExpiryDate(event);

      if (value.length < 5) {
        this.dateSuccess = false;
        return;
      }

      this.showExpired = this.checkExpiryDate(event);
      this.dateSuccess = (value.length == 5 && !this.showExpired);
    },

    handleExpiryCardFieldOnBlur(event) {
      if (event.target.value.length < 5) {
        this.showExpired = true;
        this.dateSuccess = false;
      }
    },

    checkExpiryDate(event) {
      const expiryDate = this.formData.expiryDate.split("/");
      const firstValue = expiryDate[0].charAt(0);

      if (firstValue >= 2) {
        this.formData.expiryDate = "0" + firstValue;
      }

      if (event.key == '/' && this.formData.expiryDate.length == 1) {
        this.formData.expiryDate = "0" + firstValue + "/";
      }

      if (expiryDate.length == 2) {
        const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;

        if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
          return false;
        }

        return !moment(fullDate).isAfter(new Date());
      }

      return false;
    },

    handleBsb() {
      const bsbData = this.formData.bsb.split("-");
      if (bsbData.length == 2 && bsbData[1].length == 3) {
        this.showBsbValid = true;
      } else {
        this.showBsbValid = false;
      }
      this.bsbSuccess = this.showBsbValid;
      this.bsbError = this.showBsbValid;
      this.btnDisabled = false;
    },

    checkBsbValidity() {
      const bsbData = this.formData.bsb.split("-");
      this.showBsbValid = (bsbData[0].length == 3 && bsbData[1].length == 3);
      this.bsbSuccess = this.showBsbValid;
      this.bsbError = this.showBsbValid;
      this.btnDisabled = false;
    },

    checkFieldValidation(field) {
      if (field == "cardName") {
        this.cardNameValid = this.formData.name.length > 1 && !this.errors.has("cardHolder");
      } else if (field == "accountName") {
        this.accountNameValid = this.formData.accountName.length > 1;
      } else if (field == "accountNumber") {
        this.accountNumberValid = this.formData.accountNumber.length > 1 && !this.errors.has("accountNumber");
      }
    },

    getLegals() {
      this.privacyLegals = this.legals.privacy;
      this.termsAndCondition = this.legals.websiteTerms;
      this.payNowLegals = this.legals.tnc;

      if (this.payNowLegals.explicitTerms) {
        this.payNowLegals.explicitTerms = this.replaceLegalContent(
          this.payNowLegals.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.payNowLegals._id}?type=${this.payNowLegals.nature}&fullDocument=true" target="_blank">${this.payNowLegals.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
          }
        );
      }

      this.directDebitLegals = this.legals.directDebitTerms;

      if (this.directDebitLegals.explicitTerms) {
        this.directDebitLegals.explicitTerms = this.replaceLegalContent(
          this.directDebitLegals.explicitTerms,
          {
            fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.directDebitLegals._id}?type=${this.directDebitLegals.nature}&fullDocument=true" target="_blank">${this.directDebitLegals.documentName}</a>`,
            privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
            generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
          }
        );
      }
    },

    acceptPaymentTerms() {
      this.acceptTerms = !this.acceptTerms;
      if (this.tabIndex == "CARD") {
        this.formData.termId = this.payNowLegals ? this.payNowLegals._id : "";
      } else if (this.tabIndex == "DIRECT_DEBIT") {
        this.formData.termId = this.directDebitLegals ? this.directDebitLegals._id : "";
      }
    },

    closePayment() {
      this.$emit("cancelPayment");
    },

    makePayment() {
      let data = {
        ...this.formData,
        till_public_key: this.till_public_key,
      }
      this.$emit("makePayment", data);
    },

    async addCustomerPaymentMethods() {
      if (this.handleAddPaymentDisable) {
        return;
      }

      let proceedPayment = false;
      this.btnDisabled = true;

      if (this.tabIndex == 'CARD') {
        if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.numberLength == 0) {
          this.showCardNumberIsRequired = true;
          this.btnDisabled = false;
          return;
        }

        if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.cvvLength == 0) {
          this.showCvcIsRequired = true;
          this.btnDisabled = false;
          return;
        }

        if (this.formData.name === "") {
          this.showNameIsRequired = true;
          this.btnDisabled = false;
          this.showToastMessage("Card", "Please enter name on card.", "error");
          return;
        }

        if (this.formData.expiryDate == "") {
          this.showExpDateIsRequired = true;
          this.btnDisabled = false;
          this.showToastMessage("Card", "Please enter expiry date of card.", "error");
          return;
        }
      }

      if (this.tabIndex == "CARD" && this.card_status.validCvv === true && this.card_status.validNumber === true) {
        if (this.isCardAllowed) {
          proceedPayment = true;
        } else {
          this.btnDisabled = false;
          this.showToastMessage("Card", "Card type not allowed, please use one of the card types indicated below", "error");
        }
      } else if (this.tabIndex == "DIRECT_DEBIT" && this.showBsbValid === true && this.accountNumberValid === true) {
        proceedPayment = true;
      } else {
        if (this.tabIndex == "CARD") {
          let message = "";
          if (this.card_status.validCvv === false) {
            message = this.card_status.validNumber === false ? "Please enter valid card number." : "Please enter valid card.";
          } else {
            message = "Please enter valid cvc.";
          }
        this.showToastMessage("Card", message, "error");
        }
        this.btnDisabled = false;
      }

      if (proceedPayment) {
        this.interceptSubmit();
      }
    },

    async interceptSubmit() {
      this.updateData = JSON.parse(JSON.stringify(this.formData));
      const splittedExpiryDate = this.updateData.expiryDate.split("/");

      if (this.tabIndex == "CARD") {
        let cardData = {
          card_holder: this.updateData.name,
          month: splittedExpiryDate[0],
          year: splittedExpiryDate[1]
        };

        this.paymentJSObject.tokenize(cardData, this.updatePaymentMethodForPaymentRequest);
      } else if (this.tabIndex == "DIRECT_DEBIT") {
        this.updateData.bsb = this.updateData.bsb.split("-").join("");
        let paymentData = {
          accountName: this.updateData.accountName,
          bsb: this.updateData.bsb,
          accountNumber: this.updateData.accountNumber,
        };
        this.updatePaymentMethodForPaymentRequest("", paymentData);
      }
    },

    updatePaymentMethodForPaymentRequest(token, cardData) {
      if (token) {
        this.updateData.transaction_token = token;
      }

      this.updateData.transaction_data = cardData;
      this.updateData.default = this.formData.defaultPayment;
      if (this.tabIndex == "CARD") {
        this.updateData.termId = this.payNowLegals ? this.payNowLegals._id : "";
      } else if (this.tabIndex == "DIRECT_DEBIT") {
        this.updateData.termId = this.directDebitLegals ? this.directDebitLegals._id : "";
      }
      this.$emit("addCustomerPayment", this.updateData);
    },

    maskBsb(string) {
      let arr= string.replace(" ", "").split("");
      arr.splice(3, 0, "-");
      string = arr.join("");
      return string;
    },

    copyToClipBoard() {
      navigator.clipboard.writeText(this.paymentRequestDetails.paymentRequestId).then(() => {
        this.showToastMessage("Copy to Clipboard", "Copied text to clipboard", "success");
      });
    },
  },
  mounted() {
    if (this.prWithPartnerDetail && this.prWithPartnerDetail.connectorDetail) {
      this.till_public_key = this.prWithPartnerDetail.connectorDetail.publicIntegration;
    }
    this.card_options = this.prWithPartnerDetail.productCardOptions;
    this.productCardOptions = this.card_options.map(item => {
      return item.card.slug;
    });
    this.formData.paymentRequestId = this.prWithPartnerDetail._id;
    this.getLegals();
    this.getDefaultPaymentMethod();
  },
  computed: {
    handleAddPaymentDisable() {
      let validPayment = true;

      if (this.tabIndex == "CARD") {
        if (this.formData.name == "" || this.formData.expiryDate == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has("cardHolder") || this.showExpired || this.showExpDateIsRequired) {
          validPayment = false;
        }
      } else if (this.tabIndex == "DIRECT_DEBIT") {
        if (this.formData.accountName == "" || this.formData.bsb == "" || this.formData.accountNumber == "" || this.errors.has("accountNumber") || !this.showBsbValid) {
          validPayment = false;
        }
      }

      return !this.acceptTerms || !validPayment || this.btnDisabled;
    }
  },
  watch: {
    showPaymentCard(val) {
      if (val) {
        this.selectTab(this.tabIndex);
      }
    }
  }
}
</script>
